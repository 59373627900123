export const socialLinks = [
	{
		id: 1,
		name: 'Website',
		icon: 'globe',
		url: 'https://me.tecyogi.com/',
	},
	{
		id: 2,
		name: 'GitHub',
		icon: 'github',
		url: 'https://github.com/yogender-kulshrestha',
	},
	{
		id: 3,
		name: 'Twitter',
		icon: 'twitter',
		url: 'https://x.com/tecyogi',
	},
	{
		id: 4,
		name: 'LinkedIn',
		icon: 'linkedin',
		url: 'https://in.linkedin.com/in/yogender-kulshrestha',
	},
];
