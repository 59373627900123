// Projects data.
// Note: Here we are getting data from a js file, but in a different project I'll be fetching these projects from some srt of APIs.

const projects = [
	{
		id: 1,
		title: 'DoleUpp',
		category: 'Web Application',
		description: 'Donation platform, simplifying the process for users to request and receive donations.',
		img: require('@/assets/images/doleupp.png'),
		url: 'https://doleupp.com',
	},
	{
		id: 2,
		title: 'Tm2sign',
		category: 'Web Application',
		description: 'Registration and management platform for sports tournaments and events.',
		img: require('@/assets/images/tm2sign.png'),
		url: 'https://tm2sign.com/app',
	},
	{
		id: 3,
		title: 'Trip String',
		category: 'Web Application',
		description: 'Travel management platform that allows users to search, and book hotels, and activities in one place',
		img: require('@/assets/images/tripstring.png'),
		url: 'https://www.tripstring.com',
	},
	
];

export default projects;
